/* eslint-disable no-unused-vars */

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  GoldenTicketProvider,
  MetamaskConnectProvider,
  SequenceWalletConnectProvider,
  WalletConnectProvider,
} from "./context";
import { GoldenTicket } from "./pages/GoldenTicket";
import { Home } from "./pages/Home";
import { Store } from "./pages/Store";
import { Chest } from "./pages/Chest";
import { QueryClient, QueryClientProvider } from "react-query";
import MainLayout from "./context/Layout/MainLayout";
import DefiLayout from "./context/Layout/DefiLayout";
import Marketplace from "./pages/Marketplace/Marketplace";
import AssetManagement from "./pages/AssetManagement/AssetManagement";
import Rent from "./pages/Rent/Rent";
import SummonHunter from "./pages/Summon/SummonHunter";
import SingleItem from "./pages/SingleItem/SingleItem";
import AssetItem from "./pages/AssetItem/AssetItem";
import AssetHunter from "./pages/AssetHunter/AssetHunter";
import Defi from "./pages/Defi/Defi";
import Swap from "./pages/Swap/Swap";
import Staking from "./pages/Staking/Staking";
import Vesting from "./pages/Vesting/Vesting";
import VestingAdmin from "./pages/Vesting/VestingAdmin";
import Game from "./pages/Game/Game";
import Governance from "./pages/Governance/Governance";
import Summon from "./pages/Summon/Summon";
import GameLayout from "./context/Layout/GameLayout";
import Storepage from "./pages/Storepage/Storepage";
import OpenChests from "./pages/Storepage/OpenChest";
import ChestCounter from "./pages/Storepage/ChestCounter";
import ChestCounterv2 from "./pages/Storepage/ChestCounterv2";
import "./fonts/BadBlockheadFull.ttf";
import DailyReward from "./pages/Storepage/DailyReward";
import SequenceMainLayout from "./context/Layout/SequenceMainLayout";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TokenSale from "./pages/Token/Sale";
import MintComponent from "./pages/Storepage/Mint";
import Callback from "./pages/Wallet/callback";
import { PassportProvider } from "./context/PassportProvider";
import OpenChestImx from "./pages/Storepage/OpenChestImx";
import Redeem from "./pages/Redeem/Redeem";
import Socials from "./pages/Socials/Socials";
import Tasks from "./pages/Tasks/Tasks";
import BridgePage from "./pages/Bridge/BridgePage";
import BridgeFix from "./pages/Bridge/BridgeFix";

window.Buffer = window.Buffer || require("buffer").Buffer;

const queryClient = new QueryClient();

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route
          path="/"
          element={
            // <WalletConnectProvider>
            //   <MetamaskConnectProvider>
            <SequenceWalletConnectProvider>
              <Home />
            </SequenceWalletConnectProvider>
            //   </MetamaskConnectProvider>
            // </WalletConnectProvider>
          }
        />
        <Route
          path="/open-chests"
          element={
            <SequenceMainLayout>
              <ChestCounterv2 />
            </SequenceMainLayout>
          }
        />
        <Route
          path="/open-chests-imx"
          element={
            <SequenceMainLayout>
              <OpenChestImx />
            </SequenceMainLayout>
          }
        />
        <Route
          path="/games"
          element={
            <DefiLayout>
              <Game />
            </DefiLayout>
          }
        />
        <Route
          path="/my-assets"
          element={
            <SequenceMainLayout>
              <AssetManagement />
            </SequenceMainLayout>
          }
        />
        <Route
          path="/my-assets/hunter/:id"
          element={
            <SequenceMainLayout>
              <AssetHunter />
            </SequenceMainLayout>
          }
        />
        {/* <Route
          path="/rent"
          element={
            <SequenceMainLayout>
              <Rent />
            </SequenceMainLayout>
          }
        />
        <Route
          path="/rent/hunter/:id"
          element={
            <SequenceMainLayout>
              <AssetHunter />
            </SequenceMainLayout>
          }
        /> */}
        {/* <Route
          path="/daily"
          element={
            <MainLayout>
              <DailyReward />
            </MainLayout>
          }
        /> */}
        <Route
          path="/vesting"
          element={
            <MainLayout>
              <Vesting />
            </MainLayout>
          }
        />

        <Route
          path="/vesting-admin"
          element={
            <MainLayout>
              <VestingAdmin />
            </MainLayout>
          }
        />
        <Route
          path="/socials"
          element={
            <MainLayout disableWallet={true}>
              <Socials />
            </MainLayout>
          }
        />
        <Route path="/tasks" element={<Tasks />} />
        <Route
          path="/swap"
          element={
            <MainLayout disableWallet={true}>
              <Swap />
            </MainLayout>
          }
        />

        <Route
          path="/redeem"
          element={
            <MainLayout disableWallet={true}>
              <PassportProvider>
                <Redeem />
              </PassportProvider>
            </MainLayout>
          }
        />

        <Route
          path="/mint"
          element={
            <MainLayout disableWallet={true}>
              <PassportProvider>
                <MintComponent />
              </PassportProvider>
            </MainLayout>
          }
        />

        <Route
          path="/bridge"
          element={
            <MainLayout disableWallet={true}>
              <BridgePage />
            </MainLayout>
          }
        />

        <Route
          path="/bridge/fix"
          element={
            <MainLayout disableWallet={true}>
              <BridgeFix />
            </MainLayout>
          }
        />
        {/* <Route
          path="/summon"
        <Route
          path="/bridge"
          element={
            <MainLayout disableWallet={true}>
              <BridgePage />
            </MainLayout>
          }
        />
        <Route
          path="/callback"
          element={
            <MainLayout disableWallet={true}>
              <Callback />
            </MainLayout>
          }
        /> */}
        <Route path="/callback" element={<Callback />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
