/* eslint-disable no-unused-vars */
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
//img
import logo from "../../assets/images/logo.svg";
import menu from "../../assets/images/menu.svg";
import Group from "../../assets/images/Group.svg";
//config
import { useWalletConnect } from "../WalletConnectContext";
import { shortenAddress } from "../../helpers/functions.helper";
//modal
import { LinkWalletModal } from "../../components/Modal/LinkWalletModal";
//store
import { useSelector } from "react-redux";
import { Chip, Stack, Switch, styled } from "@mui/material";
import { getCookie, setCookie } from "typescript-cookie";

const NetworkSwitch = styled(Switch)(({ theme }) => ({
  padding: 6,
  width: 54,
  height: 32,
  marginTop: "3px !important",
  marginLeft: "13px !important",
  "& .Mui-checked": {
    transform: "translateX(20px)",
    color: "white !important",
    "& + .MuiSwitch-track": {
      border: "1px solid white !important",
    },
  },
  "& .Mui-checked+.MuiSwitch-track": {
    backgroundColor: "#2a1136 !important",
    border: "1px solid white !important",
  },
  "& .MuiSwitch-switchBase": {
    padding: 5,
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    border: "1px solid white !important",
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 22,
    height: 22,
    margin: 0,
  },
}));
const Header = ({ disableWallet } = {}) => {
  const stickyHeader = useRef();
  const pathname = useLocation().pathname;
  const [isMainnet, setNetwork] = useState(true);
  const navigate = useNavigate();
  const { walletAddress, isWalletConnected } = useWalletConnect();

  const [mobileNavOpen, setmobileNavOpen] = useState(false);
  const [linkWalletModal, setlinkWalletModal] = useState(false);

  const linkWalletToggle = () => {
    setlinkWalletModal(!linkWalletModal);
    if (mobileNavOpen) {
      togglemobilenav();
    }
  };

  const togglemobilenav = () => {
    setmobileNavOpen(!mobileNavOpen);
  };

  function walletCenterClose() {
    document.getElementById("walletCenter").classList.remove("active");
    if (window.scrollY < 100) {
      document.getElementById("header").style.backgroundColor = "transparent";
    }
  }

  useLayoutEffect(() => {
    const marketHeader = document.getElementById("marketHeader");
    let fixedTop = stickyHeader.current.offsetTop;
    const fixedHeader = () => {
      if (window.pageYOffset > fixedTop) {
        marketHeader.classList.add("bg-header");
      } else {
        marketHeader.classList.remove("bg-header");
      }
    };
    window.addEventListener("scroll", fixedHeader);
  }, []);

  const pendingHuntersCount = useSelector(
    (state) => state.Items.pendingHunters
  );
  const pendingHuntersStyle = {
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: "red",
    textAlign: "center",
    alignSelf: "center",
  };

  return (
    <>
      <div
        ref={stickyHeader}
        id="marketHeader"
        className={`fixedheader marketHeader ${
          mobileNavOpen ? "bg-header" : ""
        }`}
      >
        <div className={`container-x mx-auto`}>
          <div className="headerM position-relative">
            <div className="left-header">
              {/* <Link to="/">
                <div className="d-flex align-items-center">
                  <img className="logo-img" width={196} src={logo} alt="logo" />
                </div>
              </Link> */}
              {/* {(pathname === "/marketplace" || pathname === "/rent") && (
                <div className="d-none d-lg-flex market d-flex align-items-center">
                  <img src={Group} width={24} alt="Group" />
                  <p className="d-none d-lg-flex">Marketplace</p>
                </div>
              )} */}
              <Stack direction="row" spacing={2}>
                <Stack
                  direction="column"
                  alignItems="flex-end"
                  justifyContent="center"
                >
                  <a
                    href="/"
                    className="header-logo-link d-inline-block py-1 py-lg-0"
                  >
                    <img
                      className="logo-img"
                      width={196}
                      src={logo}
                      alt="logo"
                    />
                    {/* <img className="header-logo-image" src={logo} alt="Logo" /> */}
                  </a>
                  <Chip
                    className="network-chip"
                    label={isMainnet ? "MAINNET" : "TESTNET"}
                    size="small"
                    color={isMainnet ? "success" : "error"}
                  />
                </Stack>
              </Stack>
            </div>

            <div className="right-header">
              <Link className="d-none d-md-flex" to="/open-chests">
                <p
                  className={`${
                    pathname === "/open-chests" ? "selectedp" : ""
                  }`}
                >
                  Open Chests
                </p>
              </Link>

              {/* <Link className="d-none d-md-flex" to="/summon">
                <p className={`${pathname === "/summon" ? "selectedp" : ""}`}>
                  Summon
                </p>
              </Link> */}

              {/* <Link className="d-none d-md-flex" to="/rent">
                <p className={`${pathname === "/rent" ? "selectedp" : ""}`}>
                  Rent
                </p>
              </Link> */}

              <Link className="d-none d-md-flex" to="/my-assets">
                <p
                  className={`${pathname === "/my-assets" ? "selectedp" : ""}`}
                >
                  My Assets
                </p>
                {/* Add a circle with css */}
                {pendingHuntersCount > 0 && (
                  <div style={pendingHuntersStyle}>{pendingHuntersCount}</div>
                )}
              </Link>

              {/*<Link className="d-none d-md-flex" to="/marketplace">
                <p
                  className={`${
                    pathname === "/marketplace" ? "selectedp" : ""
                  }`}
                >
                  Sale
                </p>
              </Link>*/}
              {/*<Link className="d-none d-md-flex" to="/rent">
                <p className={`${pathname === "/rent" ? "selectedp" : ""}`}>
                  Rent
                </p>
              </Link>*/}
              {/*<Link className='d-none d-md-flex' to='/store' ><p className={`${pathname === '/store' ? 'selectedp':''}`}>Store</p> </Link>*/}
              {isWalletConnected ? (
                <>
                  {/*
                  <p className="d-none d-md-flex">|</p>
                  <Link className="d-none d-md-flex" to="/assetmanagement">
                    <p
                      className={`${
                        pathname === "/assetmanagement" ? "selectedp" : ""
                      }`}
                    >
                      My Assets
                    </p>
                  </Link>
                  */}
                </>
              ) : (
                <></>
              )}
              {isWalletConnected && !disableWallet && (
                <div
                  onClick={linkWalletToggle}
                  className="d-none d-md-flex idnumber"
                >
                  <span>{shortenAddress(walletAddress)}</span>
                </div>
              )}
              {!isWalletConnected && !disableWallet && (
                <div
                  onClick={linkWalletToggle}
                  className="d-none d-md-flex idnumber"
                >
                  <span>Connect Wallet</span>
                </div>
              )}

              <div
                onClick={togglemobilenav}
                className="cursor-pointer menu d-flex d-md-none"
              >
                <img src={menu} alt="menu" />
              </div>
            </div>
          </div>
          <div
            className={`pb-3 ${
              mobileNavOpen ? "d-block" : "d-none"
            } d-md-none mobile-nav-content`}
          >
            {/*<Link
              onClick={togglemobilenav}
              className="mobile-nav-links"
              to="/marketplace"
            >
              <p
                className={`${pathname === "/marketplace" ? "selectedp" : ""}`}
              >
                Sale
              </p>
            </Link>*/}
            {/*<Link
              onClick={togglemobilenav}
              className="mobile-nav-links"
              to="/rent"
            >
              <p className={`${pathname === "/rent" ? "selectedp" : ""}`}>
                Rent
              </p>
            </Link>*/}
            {/*<Link
              onClick={togglemobilenav}
              className="mobile-nav-links"
              to="/store"
            >
              <p className={`${pathname === "/store" ? "selectedp" : ""}`}>
                Store
              </p>
            </Link>*/}
            <Link
              onClick={togglemobilenav}
              className="mobile-nav-links"
              to="/open-chests"
            >
              <p
                className={`${pathname === "/open-chests" ? "selectedp" : ""}`}
              >
                Open Chests
              </p>
            </Link>
            {/* <Link
              onClick={togglemobilenav}
              className="mobile-nav-links"
              to="/rent"
            >
              <p className={`${pathname === "/rent" ? "selectedp" : ""}`}>
                Rent
              </p>
            </Link> */}
            {/* <Link
              onClick={togglemobilenav}
              className="mobile-nav-links"
              to="/summon"
            >
              <p className={`${pathname === "/summon" ? "selectedp" : ""}`}>
                Summon
              </p>
            </Link> */}
            {isWalletConnected ? (
              <Link
                onClick={togglemobilenav}
                className="mobile-nav-links"
                to="/my-assets"
              >
                <p
                  className={`${pathname === "/my-assets" ? "selectedp" : ""}`}
                >
                  My Assets
                </p>
              </Link>
            ) : (
              <></>
            )}

            {isWalletConnected && (
              <div onClick={linkWalletToggle} className="my-2 idnumber">
                <span>{shortenAddress(walletAddress)}</span>
              </div>
            )}
            {!isWalletConnected && (
              <div onClick={linkWalletToggle} className="my-2 idnumber">
                <span> Connect Wallet</span>
              </div>
            )}
          </div>
        </div>
      </div>

      <LinkWalletModal
        isOpened={linkWalletModal}
        toggleModal={linkWalletToggle}
      ></LinkWalletModal>
    </>
  );
};

export default Header;
